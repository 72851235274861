var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-main--justify flex-cross--center mt8"},[_c('div',{staticClass:"flex flex-cross--center mt8"},[_c('div',{staticClass:"fs16 bold"},[_vm._v("批量操作")]),(_vm.activeItem === '' || _vm.activeItem === 0 || _vm.activeItem == 1)?_c('a-button',{staticClass:"ml20",on:{"click":_setup.handleStop}},[_vm._v("停止")]):_vm._e(),(_vm.activeItem === '' || _vm.activeItem == 1)?_c('a-button',{staticClass:"ml20",on:{"click":_setup.handleFinish}},[_vm._v("完成")]):_vm._e(),(
        _vm.activeItem === '' ||
        _vm.activeItem === 0 ||
        _vm.activeItem == 3 ||
        _vm.activeItem == 1 ||
        _vm.activeItem == 2
      )?_c('a-button',{staticClass:"ml20",on:{"click":_setup.handlePigeonhole}},[_vm._v("归档")]):_vm._e(),(_vm.activeItem == 4)?_c('a-button',{staticClass:"ml20",on:{"click":_setup.handleRemovePigeonhole}},[_vm._v("移除归档")]):_vm._e(),(_vm.activeItem === '' || _vm.activeItem == 2)?_c('a-button',{staticClass:"ml20",on:{"click":_setup.handleFinancialSettlement}},[_vm._v("财务结算")]):_vm._e()],1),_c('div',{staticClass:"mt8"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_setup.handleExportData}},[_vm._v("导出数据")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }