import { render, staticRenderFns } from "./m-tabbar.vue?vue&type=template&id=9a415234&scoped=true&"
import script from "./m-tabbar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./m-tabbar.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a415234",
  null
  
)

export default component.exports